<template>
  <section class="uproster-login--wrap d-flex align-items-center" v-if="!isLoggedIn">
    <div class="container d-flex">
      <div class="uproster-login--img">
        <img alt="Free Trial" src="@/assets/images/free-trial.jpg" />
      </div>
      <div class="uproster-login--form">
        <h1>Sign In</h1>
        <form method="post" @submit.prevent="submitHandler">
          <div class="form-group">
            <input
              v-model.trim="login.email"
              autofocus
              class="form-control"
              placeholder="Email"
              type="text"
              autocomplete="username"
            />
            <span class="error"
              v-if="submitted && v$.login.email.required.$invalid"
              v-html="`The Email field is required.`"
            ></span>
            <span class="error"
              v-if="submitted && v$.login.email.email.$invalid"
              v-html="`The Email field is not a valid e-mail address.`"
            ></span>
          </div>
          <div class="form-group">
            <input
              v-model.trim="login.password"
              class="form-control"
              placeholder="Password"
              type="password"
              autocomplete="current-password"
            />
            <span class="error"
              v-if="submitted && v$.login.password.required.$invalid"
              v-html="`The Password field is required.`"
            ></span>
            <span class="error"
              v-if="submitted && v$.login.password.minLength.$invalid"
              v-html="`Password length should be minimum ${v$.login.password.minLength.$params.min}`"
            ></span>
            <template v-if="message">
              <span class="error">{{ message }}</span>
            </template>
          </div>
          <div class="form-group text-right">
            <a class="link" :href="forgotPasswordLink">Forgot Password ?</a>
          </div>
          <button class="primary-btn w-100" type="submit">Sign In</button>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { email, minLength, required } from "@vuelidate/validators";

export default {
  setup: () => ({ v$: useVuelidate() }),
  name: "Login",
  data: () => ({
    forgotPasswordLink: process.env.VUE_APP_FORGOT_PASSWORD_LINK,
    submitted: false,
    login: {
      email: "",
      password: "",
    },
    message: "",
  }),
  validations() {
    return {
      login: {
        email: {
          required,
          email,
        },
        password: {
          required,
          minLength: minLength(6),
        },
      },
    };
  },
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
  },
  methods: {
    async submitHandler() {
      let vm = this;
      vm.v$.$touch();
      vm.submitted = true;

      const isValidate = await vm.v$.$validate();

      if (!isValidate) return;

      try {
        vm.$store.commit("loadingStatus", true);
        await vm.$store.dispatch("login", vm.login);
        vm.$store.commit("loadingStatus", false);
        return vm.$router.push({name: 'ProfileRoster'});
      } catch (error) {
        let message = "Something went wrong. Try again later.";
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          message = error.response.data.message;
        }

        vm.message = message;
        vm.$store.commit("loadingStatus", false);
      }
    },
  },
  watch: {
    message: function (message) {
      if (message) {
        let vm = this;
        setTimeout(() => {
          vm.message = "";
        }, 5000);
      }
    },
  },
};
</script>
